import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../ui';
import './search-bar-terms.scss';
import cx from 'classnames';
import {
  trackPreviousSearchClick,
  trackTrendingSearchClick,
} from '../../../../models/analytics';
import { Tag } from '@socialchorus/shared-ui-components';

interface SearchBarTermsProps {
  previousSearchTerms: { id: number; text: string }[];
  trendingSearchTerms: { program_id: string; trending_searches: string[] };
  handleClick: (value: string) => void;
  handleKeyDown: (
    event: React.KeyboardEvent<HTMLButtonElement>,
    value: string
  ) => void;
  setShowSearchTerms: (value: boolean) => void;
}

const SearchBarTerms: React.FC<SearchBarTermsProps> = ({
  previousSearchTerms,
  trendingSearchTerms,
  handleClick,
  handleKeyDown,
  setShowSearchTerms,
}) => {
  const { t } = useTranslation();
  const { trending_searches } = trendingSearchTerms;
  const searchBarTermsRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      searchBarTermsRef.current &&
      !searchBarTermsRef.current.contains(event.target as Node)
    ) {
      setShowSearchTerms(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div ref={searchBarTermsRef} className="search-bar-terms">
        {previousSearchTerms.length > 0 && (
          <>
            <label className="search-terms-title">
              {t('discover.previous_searches')}
            </label>
            <div className="search-terms-pills-container">
              {previousSearchTerms.slice(0, 4).map((search) => (
                <Tag
                  key={`previous-${search.id}`}
                  className="search-terms-pill"
                  label={search.text}
                  variant="outline"
                  onClick={() => {
                    handleClick(search.text);
                    trackPreviousSearchClick({ search_term: search.text });
                  }}
                  onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) =>
                    handleKeyDown(e, search.text)
                  }
                />
              ))}
            </div>
          </>
        )}

        {trending_searches && trending_searches.length > 0 && (
          <>
            <label className="search-terms-title">
              {t('discover.trending_searches')}
            </label>
            <div className="search-terms-pills-container">
              {trending_searches.slice(0, 4).map((search, index) => (
                <Button
                  key={`${search}`}
                  shape="pill"
                  size="small"
                  className={cx('search-terms-pill')}
                  onClick={() => {
                    handleClick(search);
                    trackTrendingSearchClick({ search_term: search });
                  }}
                  onKeyDown={(e) =>
                    handleKeyDown(
                      e as React.KeyboardEvent<HTMLButtonElement>,
                      search
                    )
                  }
                  aria-label={search}
                >
                  {search.length > 18 ? `${search.slice(0, 18)}...` : search}
                </Button>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SearchBarTerms;
