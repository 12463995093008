import { useCloudinaryResize } from '@socialchorus/shared-ui-components';
import { useRef } from 'react';
import { CommentAttachment as CommentAttachmentType } from '../../../models/comments/types';

const CommentAttachment = ({
  attachment,
  onClick,
}: {
  attachment: CommentAttachmentType;
  onClick: () => void;
}) => {
  const imageRef = useRef(null);
  const cloudinaryImgSrc = useCloudinaryResize({
    originalImgSrc: attachment.url,
    aspectRatio: '1:1',
    imageRef: imageRef,
  });

  return (
    <img
      ref={imageRef}
      src={cloudinaryImgSrc ?? attachment.url}
      alt={attachment.altText}
      className="comment__content--attachments--image"
      onClick={onClick}
    />
  );
};

export default CommentAttachment;
