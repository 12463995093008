import MessageFrame from '../../../components/messages/message-frame';
import { AcknowledgementFooter } from '../../../components/messages/acknowledgement-footer';
import './message-detail.scss';
import { BackButton } from '../../../components/v2/content-detail/back-button';
import { useHistory } from 'react-router';
import useFeatureFlag from '../../../common/use-feature-flag';
import { Feature } from '../../../models/features/features';

const MessageDetail = ({ messageId }) => {
  const className = 'message-detail message-detail--article';
  const history = useHistory();
  const backButtonDisabled = useFeatureFlag(
    Feature.CONTENT_BACK_BUTTON_REMOVED
  );

  return (
    <>
      {!backButtonDisabled && (
        <BackButton navigateBack={() => history.goBack()} />
      )}
      <article className={className}>
        <MessageFrame messageId={messageId} />
      </article>
      <AcknowledgementFooter messageId={messageId} />
    </>
  );
};

export default MessageDetail;
