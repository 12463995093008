import { connect } from 'react-redux';
import MessageDetail from './message-detail/message-detail';
import MessageDetailsNotFound from './message-details-not-found';
import useMessageFetcher from '../../common/use-message-fetcher';
import useMessageViewer from '../../common/use-message-viewer';
import { Spinner } from '../../components/ui';
import { programMembershipSelectors } from '../../models/program-membership';
import './message-details.scss';
import { useContentHeaderMinimizer } from '../../common/use-content-header-minimizer';

const MessageDetailsOrBlank = (props) =>
  props.isActiveMembership ? <MessageDetails {...props} /> : null;

const MessageDetails = ({ match: { params } }) => {
  useMessageViewer(params.messageId);
  const { message, isFetching } = useMessageFetcher({
    messageId: params.messageId,
  });
  useContentHeaderMinimizer({ loadSuccess: !isFetching && message });

  const showNotFound = !isFetching && !message;

  if (showNotFound) return <MessageDetailsNotFound />;

  return (
    <section className="message-details">
      {isFetching ? (
        <Spinner />
      ) : (
        <MessageDetail messageId={params.messageId} />
      )}
    </section>
  );
};

const mapStateToProps = (state, ownProps) => ({
  isActiveMembership: programMembershipSelectors.getProgramMembershipIsActive(
    state,
    ownProps
  ),
});

export default connect(mapStateToProps, null)(MessageDetailsOrBlank);
